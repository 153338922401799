<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 user-page">
          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>

              <b-row>
                <b-col md="2">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <div class="d-flex">
                    <div class="flex">
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">person</i><b-skeleton width="75%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">email</i><b-skeleton width="75%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">phone</i><b-skeleton width="70%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">location_on</i><b-skeleton width="70%"></b-skeleton>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div class="container d-flex align-items-center text-center text-md-left">
          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4>{{ user.first_name }} {{ user.last_name }}</h4>

            <b-dropdown text="Contact" variant="primary">
              <b-dropdown-item :href="`mailto:${user.email}`"
                ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
              >
              <b-dropdown-item :href="`tel:${user.phone}`"
                ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-1">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title fw600">Basic Information</h5>
                </div>

                <div class="d-flex">
                  <div class="flex">
                    <h6 class="icon-align"><i class="material-icons icon-18pt mr-8pt">email</i>{{ user.email }}</h6>
                    <h6 class="icon-align"><i class="material-icons icon-18pt mr-8pt">phone</i>{{ user.phone }}</h6>
                    <h6 class="icon-align">
                      <i class="material-icons icon-18pt mr-8pt"> location_on </i
                      >{{ get(user, 'shipping_address.address') }}, {{ get(user, 'shipping_address.city') }},

                      {{ get(user, 'shipping_address.state') }}
                      {{ get(user, 'shipping_address.zipcode') }},
                      {{ get(user, 'shipping_address.country') }}
                    </h6>
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  extends: Page,

  data() {
    return {
      title: 'Student Profile',
      isLoading: true,
      user: {},
      windowWidth: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobSmallScreen() {
      return this.windowWidth <= 460;
    },
  },

  methods: {
    ...mapActions('student', ['getPurchaser', 'getRegisteredClasses', 'updateStdRegStatus']),

    get,

    async fetchStudent() {
      this.isLoading = true;

      let resp = (await this.getPurchaser(this.$route.params.id)).data;

      this.user = resp;

      this.isLoading = false;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    if (this.$route.params.id) {
      await this.fetchStudent();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
