<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Product' : 'Add New Product'"
      :container-class="null"
      class="mb-16pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img :src="image" class="img-fluid" width="40" alt="Image" v-else-if="image" />
            <i class="fas fa-shopping-bag fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'image')"
            :isUploading="isImgUploading"
            :isRequired="!image"
            placeholder="Select Image"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Product Name" label-for="product_name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter Product's Name" v-model="name" />
        <b-form-invalid-feedback :state="!$v.name.required && $v.name.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
      
      <b-form-group label="Product Category" label-for="category" label-class="form-label">
        <v-select
          id="category"
          class="form-control v-select-custom"
          label="text"
          v-model="category"
          :reduce="category => category.value"
          placeholder="Select delivery type"
          :options="categoryOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!category" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.category.required && $v.category.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
      >
     </b-form-group>
      <b-form-group label="Price" label-for="price" label-class="form-label">
        <currency-input
          id="price"
          v-model="price"
          required
          placeholder="Enter product's price"
          :allow-negative="false"
          class="form-control"
          :class="{ 'is-invalid': !$v.price.required && $v.price.$dirty }"
        />
        <b-form-invalid-feedback :state="!$v.price.required && $v.price.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="hideFromStore" name="check-button" switch>
          Hide From School Public Store?
        </b-form-checkbox>
      </b-form-group>

      <!-- <b-form-group label="Size" label-for="size" label-class="form-label">
        <b-form-input id="size" placeholder="Enter Size" v-model="size" type="number" />
      </b-form-group> -->

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <b-form-textarea id="description" placeholder="Enter Product's Description" :rows="5" v-model="description" />
        <b-form-invalid-feedback :state="!$v.description.required && $v.description.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="sizeEnable" name="check-button" switch>Offer size options?</b-form-checkbox>
      </b-form-group>

      <div class="row mb-lg-10pt" v-if="sizeEnable">
        <draggable
          class="col-sm-12 draggable-cursor"
          @start="dragging = true"
          @end="dragging = false"
          :list="sizes"
          :handle="'#handle'"
        >
          <div v-for="(fe, index) in $v.sizes.$each.$iter" :key="index" class="mb-2">
            <div class="d-flex justify-content-center">
              <div class="icon-align mt-2 mr-2">
                <i class="fas fa-bars icon-16pt mr-8pt" id="handle"></i>
              </div>

              <b-form-group class="w-100">
                <b-form-input
                  id="size"
                  placeholder="Enter Size"
                  v-model="sizes[index]"
                  :state="!fe.required && fe.$dirty ? false : null"
                />

                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>

              <div class="icon-align ml-2 mt-1">
                <b-btn
                  v-if="sizes.length > 1"
                  variant="danger"
                  @click.prevent="removeSize(index)"
                  v-b-popover.hover.right
                  :title="'Remove Size'"
                  size="sm"
                >
                  <i class="material-icons icon-18pt">close</i>
                </b-btn>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div class="d-flex justify-content-end" v-if="sizeEnable">
        <b-btn @click.prevent="addSize()" size="sm"> <i class="material-icons icon-18pt">add</i>Add More </b-btn>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Save Product</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { FmvAvatar } from 'fmv-avatar';
import { get } from 'lodash';
import Page from '@/components/Page.vue';
import vSelect from 'vue-select';

import draggable from 'vuedraggable';

import { required, requiredIf } from 'vuelidate/lib/validators';
import 'vue-swatches/dist/vue-swatches.css';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    FmvAvatar,
    draggable,
    ImageUploader,
    vSelect,
  },
  name: 'PostProduct',
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Product' : 'Add Product',
      name: '',
      price: null,

      description: '',
      isLoading: false,
      image: '',
      isImgUploading: false,
      sizes: [''],
      uploadFile: { image: null },
      uploadPercent: { image: 0 },
      uploadCancelTokenSource: { image: null },
      sizeEnable: false,
      hideFromStore: false,
      dragging: false,
      
      category: null,
      categoryOptions: [
        { text: 'Apparel', value: 'apparel' },
        { text: 'Accessories', value: 'accessories' },
        { text: 'Classroom Supplies', value: 'classroom_supplies' },
        { text: 'Clinical/Lab Supplies', value: 'clinical_supplies' },
      ]
    };
  },
  validations() {
    return {
      name: { required },
      price: { required },
      category: { required },

      description: { required },
      image: {},
      sizes: {
        $each: { required: requiredIf(() => this.sizeEnable) },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'Products',
          to: {
            name: 'manage-product-list',
          },
        },
        {
          text: this.$route.params.id ? 'Edit Product' : 'Add Product',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isImgUploading;
    },
  },

  methods: {
    ...mapActions('products', ['createProduct', 'updateProduct', 'getProduct', 'getProductImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    get,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },

    addSize() {
      this.sizes.splice(this.sizes.length, 0, '');
    },

    removeSize(index) {
      this.sizes.splice(index, 1);
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';

      try {
        const urlResp = await this.getProductImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image') {
          this.image = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            product_title: this.name,
            product_description: this.description,
            product_url: this.image,
            sizes: this.sizeEnable ? (this.sizes[0].length ? this.sizes : null) : null,
            hide_from_store: this.hideFromStore,
            school: this.getLoggedInUser.linked_entity.id,
            price: this.price,
            category: this.category,
          };
          if (this.$route.params.id) {
            await this.updateProduct({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: this.$t('Product Updated!') });
          } else {
            await this.createProduct({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'Product Added!' });
          }
          setTimeout(
            () =>
              this.$router.push({
                name: 'manage-product-list',
              }),
            250
          );
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      if (this.$route.params.id) {
        const resp = (await this.getProduct(this.$route.params.id)).data;
        this.name = resp.product_title;
        this.description = resp.product_description;
        this.image = resp.product_url;
        this.sizeEnable = get(resp, 'sizes.length') ? true : false;
        this.sizeEnable = get(resp, 'sizes.length') ? true : false;
        this.hideFromStore = resp.hide_from_store;
        this.sizes = resp.sizes ? resp.sizes : [''];
        this.price = resp.price;
        this.category = resp.category;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: 'manage-product-list',
      });
    }

    this.isLoading = false;
  },
};
</script>
